@import 'constants';
@import 'fonts';
@import 'buttons';
@import 'popover';
@import 'desktop';
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';

body {
  background-color: transparent;
  font-family: 'Troi-Regular', Fallback, sans-serif;
  overflow: hidden;
}

.centered {
  height: auto;
  margin: 0 auto;
  position: relative;
}

button {
  background: none;
  color: inherit;
  border: none;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  width: 100%;
}

.troiLogoBlack {
  width: 48px;
  height: 50px;
  background-image: url('/assets/images/logo-black.svg');
  object-fit: scale-down;
  background-repeat: no-repeat;
  background-size: contain;
}

.pointer {
  cursor: pointer;
}

.appBarBottom {
  display: none;
}
ngb-timepicker {
  line-height: 0;
}
.ngb-tp {
  .ngb-tp-input-container {
    width: 2.5em;
    &.ngb-tp-hour, &.ngb-tp-minute {
      .ngb-tp-input {
        width: 100%;
        border: none;
        min-height: 40px;
        border-bottom: 1px solid $troi-desk-color-light-grey;
        margin-bottom: 10px;
        font-size: $troi-desk-small-size;
        padding: 10px 0;
        box-sizing: border-box;
        border-radius: 0;
        &:focus {
          outline: none;
          box-shadow: none;
          border-color: $troi-main;
        }
      }
    }

  }
}
.time-separator {
  display: flex;
  height: 40px;
  align-items: center;
  align-self: end;
  margin-bottom: 5px;
}

.tooltip {
  .tooltip-inner {
    background-color: $troi-white;
    box-shadow: 0 0 15px 4px lightgrey;
    font-size: $troi-font-size-regular;
    color: $troi-color-black;
    max-width: 500px;
    font-family: 'Troi-Regular', Fallback, sans-serif;
  }

  &[data-popper-placement^='top'] .tooltip-arrow:before {
    border-top-color: $troi-white;
  }
  &[data-popper-placement^='left'] .tooltip-arrow:before {
    border-left-color: $troi-white;
  }
  &[data-popper-placement^='right'] .tooltip-arrow:before {
    border-right-color: $troi-white;
  }
  &[data-popper-placement^='bottom'] .tooltip-arrow:before {
    border-bottom-color: $troi-white;
  }
}
