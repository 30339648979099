:root {
  --main-bg-color-main: #4fadf9;
  --main-bg-color-lighter-main: #9CD1FB;
  --main-bg-color-more-lighter-main: #f5fbfe;
  --main-bg-color-divider: #d1dee6;
  --main-bg-color-accent: #ecf6fb;
  --main-bg-color-darker-main: #2B5D99;
  --main-bg-color-main-hover: #448fd0;
  --main-bg-color-darker-main-hover: #254f84;
  --main-bg-color-white: white;
}

.red {
  --main-bg-color-main: #e74c3c;
  --main-bg-color-lighter-main: #ec9d95;
  --main-bg-color-more-lighter-main: #fdf3f2;
  --main-bg-color-divider: #dec9c9;
  --main-bg-color-accent: #fceeed;
  --main-bg-color-darker-main: #cc4334;
  --main-bg-color-main-hover: #c73c2e;
  --main-bg-color-darker-main-hover: #cf4334;
}

.orange {
  --main-bg-color-main: #e67e22;
  --main-bg-color-lighter-main: #fbad50;
  --main-bg-color-more-lighter-main: #fdf7f1;
  --main-bg-color-divider: #e2d5cd;
  --main-bg-color-accent: #fcf2ed;
  --main-bg-color-darker-main: #e66900;
  --main-bg-color-main-hover: #e66c04;
  --main-bg-color-darker-main-hover: #e6600f;
}

.green {
  --main-bg-color-main: #27ae60;
  --main-bg-color-lighter-main: #7ce09c;
  --main-bg-color-more-lighter-main: #f3fcf7;
  --main-bg-color-divider: #cae0d5;
  --main-bg-color-accent: #eefcf3;
  --main-bg-color-darker-main: #20884a;
  --main-bg-color-main-hover: #248c4a;
  --main-bg-color-darker-main-hover: #208449;
}

.grey {
  --main-bg-color-main: #7f8c8d;
  --main-bg-color-lighter-main: #c4cdce;
  --main-bg-color-more-lighter-main: #f8f9f9;
  --main-bg-color-divider: #d1d9db;
  --main-bg-color-accent: #eeeded;
  --main-bg-color-darker-main: #606d6e;
  --main-bg-color-main-hover: #6e7a7b;
  --main-bg-color-darker-main-hover: #657071;
}
