@charset "UTF-8";
@import 'colors';

$main-theme: 'blue';
$primary-color: #0064ff !default;
$primary-color-light: #3f86ff !default;

$primary-bg: #000000 !default;
$primary-content-bg: #4b4b4b !default;

$primary-font: Tahoma, Arial, sans-serif !default;
$primary-font-color: #212529;
$primary-font-size: 14px !default;

$troi-main: var(--main-bg-color-main, #4fadf9);
$troi-orange: #f7941f;
$troi-orange-dark: #e0800d;
$troi-lighter-main: var(--main-bg-color-lighter-main, #9cd1fb);
$troi-more-lighter-main: var(--main-bg-color-more-lighter-main, #d8ecff);
$troi-main-divider: var(--main-bg-color-divider, #d1dee6);
$troi-main-accent: var(--main-bg-color-accent, #ecf6fb);
$troi-lighter-hover: var(--main-bg-color-lighter-hover, rgba(231, 247, 254, 0.2));
$troi-darker-main: var(--main-bg-color-darker-main, #2b5d99);
$troi-main-hover: var(--main-bg-color-main-hover, #448fd0);
$troi-darker-main-hover: var(--main-bg-color-darker-main-hover, #254f84);
$troi-white: var(--main-bg-color-white, white);
$troi-darker-font-color: #2e87ba;

$troi-color-dropzone: #f5fbfe;
$troi-color-alto: #d8d8d8;
$troi-color-gallery: #efefef;
$troi-color-alabaster: #f7f7f7;
$troi-color-almost-white: #fefefe;
$troi-color-almost-white-lighter: #fafafa;
$troi-color-silver: #c2c2c2;
$troi-color-silver-chalice: #a1a1a1;
$troi-color-gray: #878787;
$troi-color-mine-shaft: #303030;
$troi-color-black: #000000;
$troi-color-milano-red: #cc2500;
$troi-color-red: #e40000;
$troi-color-tree-poppy: #f7941f;
$troi-color-dusty-gray: #9c9c9c;
$troi-color-citron: #82ad26;

$troi-font-family-medium: 'Troi-Medium', Fallback, sans-serif;
$troi-font-family-regular: 'Troi-Regular', Fallback, sans-serif;
$troi-font-size-medium: 12px;
$troi-font-size-regular: 11px;
$troi-font-size-small: 10px;

$table-gutter: 5px;
$table-header-padding: 15px 0;
$table-body-padding: 7px 0;
$table-shadow: 0 0 30px 0 rgba(66, 71, 74, 0.2);

$mf-font-size: 1rem !default;
$mf-active-font-size: $mf-font-size * 0.8 !default;
$mf-active-top: -$mf-font-size !default;

$mf-gap: $mf-font-size * 1.5 !default;
$mf-spacer: calc($mf-font-size / 8) !default;

$mf-input-color: #333 !default;
$mf-border-color: #999 !default;
$mf-active-color: darken($mf-border-color, 10%) !default;
$mf-focus-color: #337ab7 !default;
$mf-error-color: #d9534f !default;

$mf-transition-speed: 0.28s !default;

$troi-desk-widget-shadow: 0 0 16px 0 rgba($troi-color-silver, 0.8);
$troi-desk-notification-shadow: 0 0 4px 0 $troi-color-silver;
$troi-desk-padding: 1.5rem;
$troi-desk-super-healine-size: 1.5rem;
$troi-desk-healine-size: 1rem;
$troi-desk-sub-healine-size: 0.813rem;
$troi-desk-default-size: 0.7rem;
$troi-desk-small-size: 0.6rem;

$troi-desk-color-lighter-grey: $troi-color-almost-white-lighter;
$troi-desk-color-light-grey: #d8d8d8;
$troi-desk-color-medium-grey: #90989C;
$troi-desk-color-dark-grey: #999;
$troi-desk-color-light-blue: #e4f0f9;
$troi-desk-color-lighter-blue: #f1f5f8;
$troi-desk-color-error: $troi-color-red;
$troi-desk-color-scrollbar: #e7e7e7;
$troi-desk-color-status-good: #33cc33;
$troi-desk-color-status-bad: #dd0000;

$troi-task-background-color-grey: #f5f5f5;
