a.troiButton {
  text-decoration: none;
}
.troiButton {
  border-radius: 3px;
  padding: 16px;
  margin-bottom: 12px;
  text-align: center;
  font-size: 12px;
  line-height: 11px;
  box-shadow: 0 2px 13px 0 rgba(130, 130, 130, 0.5);
  outline: none;
  &.blue {
    color: $troi-white;
    background-color: $troi-main;
    &:disabled {
      opacity: 0.5;
    }
    &:hover, &:focus {
      background-color: $troi-main-hover;
    }
  }
  &.darkerBlue {
    color: $troi-white;
    background-color: $troi-darker-main;
    &:disabled {
      opacity: 0.5
    }
    &:hover, &:focus {
      background-color: $troi-darker-main-hover;
    }
  }
}