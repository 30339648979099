
@font-face {
  font-family: 'Troi-ExtraLight';
  src: url('/assets/fonts/troi/Troi-ExtraLight.eot'); /* IE9 Compat Modes */
  src: url('/assets/fonts/troi/Troi-ExtraLight.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/fonts/troi/Troi-ExtraLight.woff2') format('woff2'), /* Super Modern Browsers */
  url('/assets/fonts/troi/Troi-ExtraLight.woff') format('woff'), /* Pretty Modern Browsers */
  url('/assets/fonts/troi/Troi-ExtraLight.ttf')  format('truetype'), /* Safari, Android, iOS */
  url('/assets/fonts/troi/Troi-ExtraLight.svg#svgFontName') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Troi-Medium';
  src: url('/assets/fonts/troi/Troi-Medium.eot'); /* IE9 Compat Modes */
  src: url('/assets/fonts/troi/Troi-Medium.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/fonts/troi/Troi-Medium.woff2') format('woff2'), /* Super Modern Browsers */
  url('/assets/fonts/troi/Troi-Medium.woff') format('woff'), /* Pretty Modern Browsers */
  url('/assets/fonts/troi/Troi-Medium.ttf')  format('truetype'), /* Safari, Android, iOS */
  url('/assets/fonts/troi/Troi-Medium.svg#svgFontName') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Troi-Regular';
  src: url('/assets/fonts/troi/Troi-Regular.eot'); /* IE9 Compat Modes */
  src: url('/assets/fonts/troi/Troi-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/fonts/troi/Troi-Regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('/assets/fonts/troi/Troi-Regular.woff') format('woff'), /* Pretty Modern Browsers */
  url('/assets/fonts/troi/Troi-Regular.ttf')  format('truetype'), /* Safari, Android, iOS */
  url('/assets/fonts/troi/Troi-Regular.svg#svgFontName') format('svg'); /* Legacy iOS */
}